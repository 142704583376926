<template>
  <tr>
    <!-- <td style="min-width: 30px !important;">
      <v-checkbox v-model="props.props.selected" primary hide-details></v-checkbox>
    </td> -->
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="text-xs-left" style="padding: 0 10px !important;">
      <strong>#{{contact.id}}</strong>
    </td>
    <td class="text-xs-left" style="padding: 0 10px !important;">
      <v-list class="talk-toolbar__title" two dense flat style="padding-top: 0px;">
        <v-list-tile avatar flat class="talk-toolbar__title-text" style="padding: 0 !important;">
          <v-list-tile-avatar>
            <v-avatar size="45">
              <img v-if="contact.file && contact.file.url" :src="contact.file.url">
              <img v-else-if="contact.type == 1" src="static/icons/user_icon_1.png">
              <img v-else src="static/icons/monitor/user-groups-96.png">
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>
              <strong v-if="props.item" style="font-size: 18px;">
                {{contact.name}}
              </strong>
              <span v-else style="font-size: 18px;">Nome não definido</span>
            </v-list-tile-title>
            <v-list-tile-sub-title>
              <v-tooltip v-if="contact.checked == 2" bottom lazy>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="green" small>
                    verified
                  </v-icon>
                </template>
                <span>
                  Contato validado
                </span>
              </v-tooltip>
              <v-tooltip v-if="contact.checked == 3" bottom lazy>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="red" small>
                    do_not_disturb_on
                  </v-icon>
                </template>
                <span>
                  Contato inválido
                </span>
              </v-tooltip>
              <span style="font-size: 12px; padding-left: 5px;">
                <span v-if="contact.type == 1">
                  <span v-if="contact.number.toString().length == 12">
                    <strong>{{ contact.number | mask('+## (##) ####-####') }}</strong>
                  </span>
                  <span v-else-if="contact.number.toString().length == 13">
                    <strong>{{ contact.number | mask('+## (##) # ####-####') }}</strong>
                  </span>
                  <span v-else>
                    {{ contact.number }}
                  </span>
                </span>
                <span v-else>- - -</span>
              </span>
            </v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </td>
    <td class="text-xs-left" style="padding: 0 10px !important;">
      <span v-if="contact.opened_talks && contact.opened_talks.length > 0" style="display: flex; justify-content: left; align-items: center; gap: 10px;">
        <strong style="font-size: 28px;">{{ contact.opened_talks.length }}</strong>
        <span v-if="$WiCheckPermission('48eab18e')" style="cursor: pointer;" @click="showOpenedTalks()">
          <strong>
            <span> 
              <span>Atendimento</span>
              <span v-if="contact.opened_talks.length > 1">s</span>
              <span> em andamento</span>
            </span>
          </strong>
          <br>
          <span style="color: blue;">Clique para ver</span>
        </span>
        <span v-else>
          <strong>
            <span> 
              <span>Atendimento</span>
              <span v-if="contact.opened_talks.length > 1">s</span>
              <span> em andamento</span>
            </span>
          </strong>
          <br>
          <span style="color: blue;">Clique para ver</span>
        </span>
      </span>
      <span v-else-if="contact.last_event_at">
        <span>Última mensagem em:</span>
        <br>
        <strong style="font-size: 14px;">
          {{ contact.last_event_at | moment('DD/MM/YYYY HH:mm') }}
        </strong>
      </span>
      <span v-else>
        <span>Sem registro de interações</span>
      </span>
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'TalkContacts',
    computed: {
      contact () {
        return this.props.item
      }
    },
    methods: {
      openTicket: function (ticketId) {
        this.$router.push({name: 'ticket', params: {id: ticketId}})
      },
      newTalk: function () {
        this.$WiApiPost({
          uri: 'talk/talk',
          data: {
            talk_contact_id: this.contact.id,
            talk_channel_id: this.contact.talk_channel_id,
            operator_id: this.$Auth.user().id,
            status: 2
          },
          config: {msgLoad: true, msgSucess: true},
          callback: (response) => {
            this.$router.push({name: 'talk-view-messages', params: {id: response.sucess.id}})
          }
        })
      },
      showOpenedTalks: function () {
        this.$WiListDialog({
          wiConfig: 'talk-opened',
          filter: {
            talk_contact_id: this.contact.id
          }
        })
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style>
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
  .talk-toolbar__title-text .v-list__tile {
    padding: 0 !important;
  }
</style>